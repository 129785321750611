import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import backend from "api/backend";
import ConfirmationDialog from "components/ConfirmationDialog";
import CustomizedIconChoice from "components/Edit/CustomizedIconChoice";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import { RowDnDProductInCategory } from "components/Edit/DnD/RowDnDProductInCategory";
import ContentsTabsPanel from "components/Edit/Entry/ContentsTabsPanel";
import Wysiwyg from "components/Edit/Wysiwyg";
import EditViewLanguage from "components/EditViewLanguage";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import CategoryEditListDialogControled from "modules/CMSDocuments/CategoryEditListDialogControled";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import { isSuccess } from "utils/http";
import * as Yup from "yup";
import AddOpinionDialog from "./AddOpinionDialog";
import CustomizedColorPicker from "components/Edit/CustomizedColorPicker";
import Divider from "@mui/material/Divider";
import SelectDiscountCodeBanner from "components/SelectDiscountCodeBanner";
import CustomizedMetaTextField from "components/Edit/CustomizedMetaTextField";

const endpoint = "/category/";
const title = "shop_category_edit_label";
const moduleUrl = "/shop/categories/";

const MAX_SHORT_LIST = 5;

const CategoryEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation("common");
  const current = useSelector(selectEditItem);

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Pole wymagane").nullable(),
    shortName: Yup.string().trim().nullable(),
    url: Yup.string().trim().required("Pole wymagane").nullable(),
    metaTitle: Yup.string().trim().nullable(),
    metaDescription: Yup.string().trim().nullable(),
    image: Yup.string().trim().nullable(),
    imageAltText: Yup.string().trim().nullable(),
    icon: Yup.string().trim().nullable(),
    iconAltText: Yup.string().trim().nullable(),
    intro: Yup.string().trim().nullable(),
    isProductsChanged: Yup.boolean().nullable(),
    topWithBanner: Yup.boolean().nullable(),
    topImage: Yup.string().trim().max(1024).nullable(),
    topMobileImage: Yup.string().trim().max(1024).nullable(),
    topImageAlt: Yup.string().trim().max(1024).nullable(),
    topActionButton: Yup.string().trim().max(512).nullable(),
    topDisclaimer: Yup.string().trim().max(2048).nullable(),
    hiddenPagination: Yup.boolean().nullable(),
    topTitle: Yup.string().trim().max(2048).nullable(),
    topSubtitle: Yup.string().trim().max(2048).nullable(),
    flatTabs: Yup.boolean().nullable(),
    backgroundColor: Yup.string().required("Pole wymagane"),
    iconColor: Yup.string().required("Pole wymagane"),
    promotionDiscountCodeId: Yup.string().trim().nullable(),
    promotionDiscountCodeItemId: Yup.string()
      .when("promotionDiscountCodeId", {
        is: (p) => !p || p === "",
        then: Yup.string().trim().nullable(),
      })
      .when("promotionDiscountCodeId", {
        is: (p) => p && p !== "",
        then: Yup.string().trim().required(t("form-validation-required")),
      }),
    opinions: Yup.array(
      Yup.object({
        id: Yup.number().nullable(),
        categoryLanguage: Yup.object().nullable(),
        author: Yup.string().trim().max(1024).nullable(),
        rate: Yup.number().nullable(),
        content: Yup.string().trim().max(2048).nullable(),
        date: Yup.date().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
    banners: Yup.array(
      Yup.object({
        id: Yup.number().nullable(),
        bannerId: Yup.number().nullable(),
        name: Yup.string().trim().nullable(),
        displayOrder: Yup.number().nullable(),
      }),
    ),
    products: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        name: Yup.string().trim().nullable(),
        url: Yup.string().trim().nullable(),
      }),
    ),
    tabs: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        name: Yup.string().nullable(),
        content: Yup.string().nullable(),
        sortOrder: Yup.number().nullable(),
      }),
    ),
    excludeFromSiteMap: Yup.bool().nullable(),
  });

  const defaultValueForm = {
    name: "",
    shortName: "",
    url: "",
    metaTitle: "",
    metaDescription: "",
    image: "",
    imageAltText: "",
    icon: "",
    iconAltText: "",
    intro: "",
    products: [],
    tabs: [],
    banners: [],
    opinions: [],
    isProductsChanged: false,
    backgroundColor: "#F3F1FF",
    iconColor: "#8383ea",
    promotionDiscountCodeId: "",
    promotionDiscountCodeItemId: "",
    excludeFromSiteMap: false,
    topImage: "",
    topMobileImage: "",
    topImageAlt: "",
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        defaultValue={defaultValueForm}
        refForm={refForm}
        preview={true}
        deleteButtonTitle={"Usuń Kategorię"}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditViewLanguage>
    </>
  );
};

const FormData = ({ id, current, refForm }) => {
  const { control, setValue, getValues, formState } = useFormContext();

  console.info(formState);

  const { t } = useTranslation("common");
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isMorePinedProducts, setIsMorePinedProducts] = useState(false);
  const [openedDialogChoiceProducts, setOpenedDialogChoiceProducts] = useState(false);

  const [isOpenDialogSelectBanners, setIsOpenDialogSelectBanners] = useState(false);
  const [isMoreBanners, setIsMoreBanners] = useState(false);
  const [allActiveBanners, setAllActiveBanners] = useState(null);

  const [allProducts, setAllProducts] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [isAllProductReceived, setIsAllProductRecived] = useState(false);

  const products = useWatch({
    control,
    name: `products`,
  });

  const topWithBannerStatus = useWatch({
    control,
    name: `topWithBanner`,
  });

  const opinions = useWatch({
    control,
    name: `opinions`,
  });

  const watchBanners = useWatch({
    control,
    name: `banners`,
  });

  useEffect(() => {
    backend.get("/banner/getAllPageBanner").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setAllActiveBanners(
            res.data.filter((item) => {
              return item.active;
            }),
          );
        }
      }
    });
    return () => {
      setAllActiveBanners([]);
    };
  }, []);

  useEffect(() => {
    backend.get("/product/getAll").then((res) => {
      if (isSuccess(res)) {
        if (res.data.length > 0) {
          setActiveProducts(
            res.data.filter((item) => {
              return item.active;
            }),
          );
          setAllProducts(res.data);
        }
        setIsAllProductRecived(true);
      }
    });
    return () => {
      setAllProducts([]);
    };
  }, []);

  useEffect(() => {
    if (products?.length > 0 && allProducts?.length > 0) {
      const newFields = [];

      for (const item of products) {
        const searchItem = allProducts.filter((x) => x.id == item.id);
        if (searchItem && searchItem.length > 0) {
          newFields.push({ ...item, ...searchItem[0] });
        } else {
          newFields.push({ ...item, name: "?" });
        }
      }

      setValue("products", newFields);
    }
  }, [current, allProducts]);

  const moveItem = (dragIndex, hoverIndex, fieldName) => {
    const fields = getValues(fieldName);
    const myMove = (from, to, array) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return [...array];
    };

    const newFields = myMove(dragIndex, hoverIndex, fields);
    setValue(fieldName, newFields);
  };

  const handleClose = ({ isListChanged }) => {
    setOpenedDialogChoiceProducts(false);
    if (isListChanged) {
      setValue("isProductsChanged", true);
    }
  };

  const defaultValues = {
    id: "-1",
    author: "",
    content: "",
    date: new Date(),
    rate: 5,
    sortOrder: 0,
  };

  const [isOpeneAddOpinion, setOpenAddOpinion] = useState(false);
  const [opinionData, setOpinionData] = useState(defaultValues);

  const handleAddOpinion = () => {
    setOpinionData(defaultValues);
    setOpenAddOpinion(true);
  };

  const handleCloseAddOpinion = () => {
    setOpenAddOpinion(false);
  };

  const handleAddSaveOpinion = (data) => {
    if (data.id === -1) {
      const opinions = getValues("opinions");
      setValue("opinions", [...opinions, { ...data, sortOrder: opinions.length + 1 }]);
    } else {
      //update opinion by id
      const opinions = getValues("opinions");
      const newOpinions = opinions.map((item) => {
        if (item.id === data.id) {
          return data;
        }
        return item;
      });
      setValue("opinions", newOpinions);
    }

    setOpenAddOpinion(false);
  };

  const handleEditOpinion = (data) => {
    setOpinionData(data);
    setOpenAddOpinion(true);
  };

  const handleDelOpnion = (id) => {
    const opinions = getValues("opinions");
    const newOpinions = opinions.filter((item) => item.id !== id);
    setValue("opinions", newOpinions);
    setOpenAddOpinion(false);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa skrócona"} name={"shortName"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"URL"} name={"url"} />
          </Grid>
          <Grid item xs={4} sm={2}>
            <CustomizedSwitch
              name={"active"}
              topLabel={t("cms_edit_page_state")}
              rightLabel={t("cms_edit_page_online")}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <CustomizedSwitch name={"excludeFromSiteMap"} topLabel={"Usuń z sitemapy"} rightLabel={"Tak"} />
          </Grid>
          <Grid item xs={4} sm={2}>
            <CustomizedSwitch
              name={"hiddenPagination"}
              topLabel={t("cms_edit_category_pagination")}
              rightLabel={t("cms_edit_category_pagination_status")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomizedImagePicker
                  nameAlt={"imageAltText"}
                  nameUrl={"image"}
                  label={"Obrazek strona główna"}
                  placeholder={"Wybrany obrazek:"}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Obrazek strona główna - tekst alternatywny"} name={"imageAltText"} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomizedIconChoice name={"icon"} label={"Ikona"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Ikona - tekst alternatywny"} name={"iconAltText"} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedColorPicker name={"backgroundColor"} label={t("cms_banner_background_color")} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomizedColorPicker name={"iconColor"} label={t("cms_banner_background_icon_color")} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomizedSwitch
              name={"topWithBanner"}
              topLabel={t("cms_edit_category_page_type")}
              rightLabel={t("cms_edit_category_page_type_status")}
            />
          </Grid>
          {topWithBannerStatus && (
            <>
              <Grid item xs={6}>
                <CustomizedImagePicker
                  nameUrl={"topImage"}
                  label={"Obrazek tła desktop"}
                  placeholder={"Wybrany obrazek:"}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedImagePicker
                  nameUrl={"topMobileImage"}
                  label={"Obrazek tła mobile"}
                  placeholder={"Wybrany obrazek:"}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Tekst alternatywny obrazka"} name={"topImageAlt"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Tekst nagłówek w bannerze"} name={"topDisclaimer"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Tekst buttona akcji"} name={"topActionButton"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Tytuł kategorii"} name={"topTitle"} />
              </Grid>
              <Grid item xs={12}>
                <CustomizedTextField label={"Podtytuł kategorii"} name={"topSubtitle"} />
              </Grid>
            </>
          )}
          {!topWithBannerStatus && (
            <Grid item xs={12}>
              <Wysiwyg label={"Zajawka"} name={"intro"} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <SelectDiscountCodeBanner />
        </Grid>
      </Paper>

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              {t("cms_edit_seo_data")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <CustomizedMetaTextField label={"Meta title"} name={"metaTitle"} type="TITLE" />
            <br />
            <CustomizedMetaTextField label={"Meta description"} name={"metaDescription"} type="DESCRIPTION" />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Opinie naszych klientów
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            {opinions?.length < 3 && (
              <Box sx={{ textAlign: "left", mb: 2 }}>
                <Button variant="contained" color="secondary" size="small" onClick={handleAddOpinion}>
                  Dodaj opinie
                </Button>
              </Box>
            )}
            {opinions?.length === 0 && (
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <Typography>Brak opinii</Typography>
              </Box>
            )}
            {opinions?.length > 0 && (
              <Grid container>
                {opinions?.map((item, index) => (
                  <Grid item xs={4} key={index}>
                    <Paper
                      sx={{ p: 5, borderRadius: 0, cursor: "pointer" }}
                      elevation={3}
                      onClick={() => handleEditOpinion(item)}
                    >
                      <Box display="flex" justifyContent="space-between" alignItems="baseline">
                        <Typography variant="h6" component={"h6"}>
                          {item.author}
                        </Typography>
                        <Box display="flex" gap={2} alignItems="center">
                          <Typography>{item.rate}</Typography>
                          <ReactStars value={item.rate} count={5} edit={false} size={16} activeColor="#ffd700" />
                        </Box>
                      </Box>
                      <Typography variant="body2" component={"p"} sx={{ color: "#727B89", fontSize: "14px" }}>
                        {moment(item.date).format("DD.MM.YYYY")}
                      </Typography>
                      <hr />
                      <Typography variant="body1" component={"p"} sx={{ fontSize: "13px" }}>
                        {item.content}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>

      {isOpeneAddOpinion && (
        <AddOpinionDialog
          open={isOpeneAddOpinion}
          handleClose={handleCloseAddOpinion}
          handleSubmit={handleAddSaveOpinion}
          handleDeleteItem={handleDelOpnion}
          defaultValues={opinionData}
        />
      )}

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Treść główna
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Box mb={5}>
              <CustomizedSwitch
                name={"flatTabs"}
                topLabel={t("cms_edit_category_flat_tabs")}
                rightLabel={t("cms_edit_category_flat_tabs_status")}
              />
            </Box>
            <Box>
              <ContentsTabsPanel />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <ConfirmationDialog
        open={isOpenConfirmationModal}
        close={() => setOpenConfirmationModal(false)}
        confirm={() => setOpenConfirmationModal(false)}
      />

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Produkty w kategorii
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setOpenedDialogChoiceProducts(true)}
              >
                Edytuj listę
              </Button>
            </Box>

            {!isAllProductReceived ? (
              <CircularProgress size={20} />
            ) : !products?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {products?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMorePinedProducts) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex, "products")}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.url}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={products}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMorePinedProducts}
                  setFullListOpen={setIsMorePinedProducts}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
      {isAllProductReceived && (
        <CategoryEditListDialogControled
          handleClose={handleClose}
          open={openedDialogChoiceProducts}
          allItemsTable={Array.isArray(activeProducts) ? activeProducts : []}
          nameFormTables={"products"}
          nameToDisplay={"name"}
          keepSortOrder
        />
      )}

      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              Bannery w kategorii
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setIsOpenDialogSelectBanners(true)}
              >
                Edytuj listę
              </Button>
            </Box>
            {!watchBanners?.length > 0 ? (
              <Typography>Brak elementów</Typography>
            ) : (
              <>
                <Table sx={{ pb: 0 }}>
                  <TableBody>
                    <DndProvider backend={HTML5Backend}>
                      {watchBanners?.map(
                        (item, index) =>
                          (index < MAX_SHORT_LIST || isMoreBanners) && (
                            <RowDnDProductInCategory
                              key={item.id}
                              id={item.id}
                              handleMoveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex, "banners")}
                              index={index}
                              primaryText={item.name}
                              secondaryText={item.buttonHref}
                            />
                          ),
                      )}
                    </DndProvider>
                  </TableBody>
                </Table>
                <MoreLessBoxIcon
                  list={watchBanners}
                  maxShortListElement={MAX_SHORT_LIST}
                  fullListOpen={isMoreBanners}
                  setFullListOpen={setIsMoreBanners}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
      {allActiveBanners && (
        <CategoryEditListDialogControled
          handleClose={() => setIsOpenDialogSelectBanners(false)}
          open={isOpenDialogSelectBanners}
          allItemsTable={allActiveBanners}
          nameFormTables={"banners"}
          nameToDisplay={"name"}
        />
      )}
    </>
  );
};

export default CategoryEdit;
